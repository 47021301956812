import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { 
	AUTH_PREFIX_PATH, 
	UNAUTHENTICATED_ENTRY, 
	REDIRECT_URL_KEY 
} from 'configs/AppConfig'
import Cookies from 'js-cookie';
import { decodeAuth } from 'utils/Helper';

const ProtectedRoute = () => {
	const location = useLocation();
	const getAuth = Cookies.get('vb\C#^*gW589');
	const dcode = getAuth ? decodeAuth(getAuth): null;
	let getRole = localStorage.getItem("last_login_role");
	// if(getRole && getRole === "admin"){
	// 	return <Navigate to={`${AUTH_PREFIX_PATH}/admin${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
	// };
	if (!getAuth) {
		return <Navigate to={`${AUTH_PREFIX_PATH}/${getRole === 'admin'? 'admin':'agent'}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
	};
	
	if(!dcode?.user?.is_password_changed){
		return <Navigate to={`${AUTH_PREFIX_PATH}/change-password`} replace />;
	};

	return <Outlet />
	
}

export default ProtectedRoute