import React  from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig'
import Cookies from 'js-cookie';
import { decodeAuth } from 'utils/Helper';

const PublicRoute = () => {
	const getAuth = Cookies.get('vb\C#^*gW589');
	const dcode = getAuth ? decodeAuth(getAuth): null;
	return getAuth ? (!dcode?.user?.is_password_changed ? <Outlet/>:<Navigate to={AUTHENTICATED_ENTRY} /> ) : <Outlet/>
}

export default PublicRoute